export default function DownArrowIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g>
                <mask maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                    <rect width="20" height="20" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_1739_3264)">
                    <path d="M9.16667 13.4791V4.16659C9.16667 3.93047 9.24653 3.73256 9.40625 3.57284C9.56597 3.41311 9.76389 3.33325 10 3.33325C10.2361 3.33325 10.434 3.41311 10.5938 3.57284C10.7535 3.73256 10.8333 3.93047 10.8333 4.16659V13.4791L14.9167 9.39575C15.0833 9.22909 15.2778 9.14922 15.5 9.15617C15.7222 9.16311 15.9167 9.24992 16.0833 9.41659C16.2361 9.58325 16.316 9.7777 16.3229 9.99992C16.3299 10.2221 16.25 10.4166 16.0833 10.5833L10.5833 16.0833C10.5 16.1666 10.4097 16.2256 10.3125 16.2603C10.2153 16.2951 10.1111 16.3124 10 16.3124C9.88889 16.3124 9.78472 16.2951 9.6875 16.2603C9.59028 16.2256 9.5 16.1666 9.41667 16.0833L3.91667 10.5833C3.76389 10.4305 3.6875 10.2395 3.6875 10.0103C3.6875 9.78117 3.76389 9.58325 3.91667 9.41659C4.08333 9.24992 4.28125 9.16658 4.51042 9.16658C4.73958 9.16658 4.9375 9.24992 5.10417 9.41659L9.16667 13.4791Z" fill="#1C1B1F"/>
                </g>
            </g>
        </svg>
    );
};