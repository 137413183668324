import styles from './select-input.module.css';

export default function SelectInputComponent({
    choices,
    selectedChoice,
    onChoiceUpdate,
}) {
    return (
        <select className={styles.selectInput} onInput={e => onChoiceUpdate && onChoiceUpdate(e.target.value)} value={selectedChoice}>
            {
                choices != null && choices.map(choice =>(
                    <option value={choice}>{ choice }</option>
                ))
            }
        </select>
    );
};