import ButtonComponent from "../button/button";
import styles from "./icon-button.module.css";

export default function IconButtonComponent({
    icon,
    color,
    shape,
    onClick,
    disabled,
    className
}) {
    return (
        <ButtonComponent 
            className={`${styles.iconButton} ${!shape || shape == 'circle' ? styles.circle : ''} ${shape == 'square' ? styles.square : ''} ${shape == 'none' ? styles.none : ''} ${className ? className : ''}`}
            color={color}
            onClick={onClick}
            disabled={disabled}
        >
            { icon }
        </ButtonComponent>
    )
};