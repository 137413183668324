import { useEffect, useRef, useState } from "react";
import MessageWrapperComponent from "../../components/messages-wrapper/messages-wrapper";
import styles from "./conversation.module.css";
import MessageComponent from "../../components/message/message";
import llmService from "../../services/api/llm.service";
import authService from "../../services/api/auth.service";
import storageService from "../../services/storage.service";

import { useIsAuthEffect } from "../../utils/auth.utils";
import FixedWrapperComponent from "../../components/fixed-wrapper/fixed-wrapper";
import ErrorMessageComponent from "../../components/error-message/error-message";
import HubIcon from "../../components/icons/hub-icon/hub-icon";
import IconButtonComponent from "../../components/icon-button/icon-button";
import CircleArrowIcon from "../../components/icons/circle-arrow-icon/circle-arrow-icon";
import DownArrowIcon from "../../components/icons/down-arrow-icon/down-arrow-icon";
import { isElementScrollAtBottom } from "../../utils/dom.utils";
import TrashcanIcon from "../../components/icons/trashcan-icon/trashcan-icon";
import config from "../../config/app.config";
import SelectInputComponent from "../../components/select-input/select-input";

const availableModes = [
    "auto",
    "graph",
    "impact"
];

export default function ConversationModule({
    navigate
}) {

    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [selectedMode, setSelectedMode] = useState(availableModes[0]);

    const [authenticating, setAuthenticating] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [mustCheckContentScroll, _requestCheckContentScroll] = useState(null);
    const conversationWrapperRef = useRef();
    const [showDownButton, setShowDownButton] = useState(false);

    const requestCheckContentScroll = () => _requestCheckContentScroll(Date.now());

    const onSubmitInput = () => {
        if(fetching) return;
        setFetching(true);

        const newMessages = [
            ...messages,
            {
                role: 'user',
                content: input
            }
        ];

        setInput('');

        setMessages([ 
            ...newMessages,
            {
                role: 'assistant',
                loading: true,
                hidden: true
            }
        ]);

        // Delayed message visual effect
        setTimeout(() => {
            setMessages(messages => {
                messages.filter(msg => msg.hidden).forEach(msg => delete msg['hidden']);
                return [ ...messages ];
            });
        }, 750);

        let requestPromise = null;

        requestPromise = llmService.requestLLM( processMessagesToApi(newMessages), selectedMode );

        if(!requestPromise) return;
        

        requestPromise.then(res => {
            setFetching(false);
            
            if(!res) setErrorMessage("Une erreur s'est produite avec le LLM."); // Show error message

            setMessages(messages => {
                const loadingMessages = messages.filter(msg => msg.loading === true);

                // Apply response data to first loading message found
                if(loadingMessages.length > 0) {
                    const loadingMessage = loadingMessages[0];
                    delete loadingMessage['loading'];
                    delete loadingMessage['hidden'];

                    if(!res) {
                        loadingMessage.error = true;
                    }
                    else {
                        loadingMessage.generated = res.llm_answer;
                        loadingMessage.trace = res.trace;
                        loadingMessage.content = res.database_results;
                    }
                }

                return [...messages];
            });
        });
    };

    const onDownClick = () => {
        if(!conversationWrapperRef.current) return;

        const messagesWrapper = conversationWrapperRef.current.querySelector('div');
        if(!messagesWrapper) return;

        messagesWrapper.scrollTo({
            top: messagesWrapper.scrollHeight,
            behavior: 'smooth'
        });
    };

    useIsAuthEffect({
        onSuccess: () => setAuthenticating(false),
        onFailed: () => {
            if(config.appMode == 'dev') {
                navigate(config.authMode == 'internal' ? '/' : '/no-token');
                return;
            }

            if(config.authMode == 'internal')
                navigate('/');
            else // External
                window.location.href = config.authExternalUrl ? config.authExternalUrl : '/';
        }
    });

    // Down scroll check effects
    {
        useEffect(() => {
            onDownClick();
        }, [messages]);
    
        useEffect(() => {
            if(!conversationWrapperRef.current) return;

            const messagesWrapper = conversationWrapperRef.current.querySelector('div');
            if(!messagesWrapper) return;

            setShowDownButton(!isElementScrollAtBottom(messagesWrapper));
        }, [mustCheckContentScroll]);
    }

    const canSubmit = !fetching && !authenticating && input.length > 0;
    return (
        <div className={styles.conversationWrapper} ref={conversationWrapperRef}>
            <PresentationModule 
                text="Votre assistant IA Bom Services"
                hidden={messages.length > 0}
                /*availableModes={Object.values(LLM_MODES)}
                selectedMode={llmMode}
                onModeChange={mode => setLLMMode(mode)}*/
            />
            <MessageWrapperComponent
                messages={messages}
                onScroll={() => requestCheckContentScroll()}
            />
            <div 
                className={`${styles.downWrapper}`}
            >
                {
                    messages.length > 0 && (
                        <select 
                            className={styles.detectionMode}
                            value={selectedMode}
                            onChange={e => setSelectedMode(e.target.value)}
                            title="Changer le mode de réfléxion"
                        >
                            {
                                availableModes.map(mode => (
                                    <option value={mode}>{mode}</option>
                                ))
                            }
                        </select>
                    )
                }
                <IconButtonComponent
                    icon={<DownArrowIcon />}
                    shape="circle"
                    color="transparent"
                    className={`${styles.downButton} ${(!messages.length || !showDownButton) ? styles.hidden : ''}`}
                    onClick={onDownClick}
                />
                {
                    messages.length > 0 && (
                        <IconButtonComponent
                            icon={<TrashcanIcon />}
                            className={styles.resetButton}
                            onClick={() => {
                                setMessages([]);
                                setSelectedMode(availableModes[0]);
                            }}
                            shape="none"
                            color="transparent"
                            disabled={!messages.length || authenticating || fetching}
                            title="Réinitialiser la conversation"
                        />
                    )
                }
            </div>
            <div className={styles.inputForm}>
                <input
                    type="text"
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    onKeyUp={e => e.code == 'Enter' && canSubmit && onSubmitInput()}
                    placeholder="Posez-moi une question"
                    className={styles.input}
                />
                <IconButtonComponent
                    icon={<CircleArrowIcon />}
                    className={`${styles.submitButton} ${canSubmit ? styles.canSubmit : ''}`}
                    onClick={() => canSubmit && onSubmitInput()}
                    shape="none"
                    color="transparent"
                    disabled={!canSubmit}
                />
            </div>
            {
                errorMessage && (
                    <FixedWrapperComponent>
                        <ErrorMessageComponent
                            anim
                            onClose={() => setErrorMessage(null)}
                        >
                            { errorMessage }
                        </ErrorMessageComponent>
                    </FixedWrapperComponent>
                )
            }
        </div>
    );
};

function PresentationModule({
    text,
    hidden,
    availableModes,
    selectedMode,
    onModeChange
}) {
    return (
        <span 
            className={`${styles.presentation} ${hidden ? styles.hidden : ''}`}
        >
            <HubIcon />
            <h3 className={styles.presentationText}>{text}</h3>
            {
                availableModes != null && (
                    <SelectInputComponent
                        choices={availableModes}
                        selectedChoice={selectedMode}
                        onChoiceUpdate={onModeChange}
                    />
                )
            }
        </span>
    );
};

function processMessagesToApi(messages) {
    return messages
        .filter((message, index, arr) => (arr.length-1 <= index || message.role != 'user' || !arr[index+1].error)) // Remove user's messages that caused errors
        .filter(message => !message.loading && !message.error) // Remove error/loading messages
        .map(message => ({ role: message.role, content: message.content })); // Clean messages format
};