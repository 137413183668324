import UserIcon from "../icons/user-icon/user-icon";
import MessageComponent from "../message/message";

export default function UserMessageComponent({
    message
}) {
    return (
        <MessageComponent
            fromIcon={<UserIcon />}
            from="User"
        >
            { message.content }
        </MessageComponent>
    );
};