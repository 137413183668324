import copyToClipboard from "../../utils/clipboard.utils";
import IconButtonComponent from "../icon-button/icon-button";
import ClipboardIcon from "../icons/clipboard-icon/clipboard-icon";
import LinkIcon from "../icons/link-icon/link-icon";
import LLMIcon from "../icons/llm-icon/llm-icon";
import MessageComponent from "../message/message";
import styles from "./llm-message.module.css";

export default function LlmMessageComponent({
    message,
    messageIndex
}) {
    const messageResults = message.content;// message.content ? processMessageResults(message.content) : null;

    return (
        <MessageComponent
            fromIcon={<LLMIcon />}
            from="LLM"
        >
            <div className={styles.content}>
                <div className={styles.contentItem}>
                    <p className={styles.contentItemText}>
                        { !message.loading && !message.error && message.generated }
                        { message.error && (<span className={styles.errorMessage}>Erreur</span>) }
                        { message.loading && (<LoadingContentComponent />) }
                    </p>
                    <IconButtonComponent
                        icon={<ClipboardIcon />}
                        color="transparent"
                        className={styles.contentCopyButton}
                        onClick={() => copyToClipboard(message.generated)}
                        disabled={message.loading || message.error}
                    />
                </div>
                <div className={styles.contentItem}>
                    <p className={`${styles.contentItemText} ${styles.dark}`}>
                        { 
                            !message.loading && !message.error && message.trace != null && 
                                Object.entries(message.trace).map(([k,v]) => (<TraceItemComponent label={k} value={v} />))
                        }
                        { message.error && (<span className={styles.errorMessage}>Erreur</span>) }
                        { message.loading && (<LoadingContentComponent />) }
                    </p>
                    <IconButtonComponent
                        icon={<ClipboardIcon />}
                        color="transparent"
                        className={styles.contentCopyButton}
                        onClick={() => copyToClipboard(message.trace)}
                        disabled={message.loading || message.error}
                    />
                </div>
            </div>
            { // Handle old content display (disabled)
                /*messageResults != null && messageResults.length > 0 && (
                    <div className={styles.resultsContent}>
                        <div className={styles.resultsHeader}>
                            { messageResults.length }
                        </div>
                        <div className={styles.resultsBody}>
                            {
                                messageResults.map((result, index) => (
                                    <span key={`message-${messageIndex}-results-${index}`}>{result}</span>
                                ))
                            }
                        </div>
                    </div>
                )*/
            }
            { // Handle database_result
                messageResults != null && messageResults.length > 0 && messageResults.map((tableData, index) => (
                    <ResultTableComponent key={`table-${index}`} title={tableData.title} data={tableData.data} />
                ))
            }
        </MessageComponent>
    );
};

function LoadingContentComponent() {
    return (
        <span 
            className={styles.loadingContent}
        >
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </span>
    );
};

function TraceItemComponent({
    label,
    value
}) {
    return (
        <li>{label} : {value}</li>
    );
};

function ResultTableComponent({
    title,
    data
}) {
    const dataKeys = getDataKeys(data);

    return (
        <div className={styles.resultTable}>
            <div>
                <h2>{ title }</h2>
                <label className={styles.dataSize}>({ data.length })</label>
            </div>
            <table className={styles.resultTableContent}>
                <thead>
                    {
                        dataKeys.map(key => (
                            <th>{ key }</th>
                        ))
                    }
                </thead>
                <hr className={styles.tableDivider} />
                <tbody>
                    {
                        data.map(item => (
                            <tr>
                                {
                                    dataKeys.map(k => {
                                        let itemValue = item[k];

                                        if(itemValue === undefined) itemValue = "";
                                        itemValue = String(itemValue);

                                        const loweredItemValue = itemValue.toLowerCase();
                                        if(loweredItemValue.startsWith('http://') || loweredItemValue.startsWith('https://'))
                                            return (<td> <a href={itemValue} target="_blank"><LinkIcon /></a> </td>);

                                        return (<td>{ itemValue }</td>);
                                    })
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};

function getDataKeys(data) {
    const outKeys = [];

    if(data && data instanceof Array) {
        for(const item of data) {
            Object.keys(item).forEach(k => !outKeys.includes(k) && outKeys.push(k));
        }
    }

    return outKeys;
};

// Deprecated
function processMessageResults(results) {
    let parsedResults = null;

    try {
        parsedResults = JSON.parse(results.replace(/'/g, '"'));

        if(!Array.isArray(parsedResults)) throw new Error();
    }
    catch {
        return [];
    }

    const out_results = [];

    // Recursive function to parse neo's results
    const parseResult = (result) => {
        for(const item of result) {
            if(Array.isArray(item)) {
                parseResult(item);
                continue;
            }
            out_results.push(Object.values(item)[0]); // SALE
        }
    };
    parseResult(parsedResults);

    return out_results;
};