import NoTokenModule from "../modules/no-token/no-token";


export default function NoTokenPage({
    navigate
}) {
    return (
        <>
            <NoTokenModule />
        </>
    );
};