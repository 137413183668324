import styles from "./fixed-wrapper.module.css";

export default function FixedWrapperComponent({
    absolute,
    children
}) {
    return (
        <div className={`${styles.fixedWrapper} ${absolute ? styles.absolute : ''}`}>
            { children }
        </div>
    );
};