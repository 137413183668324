import config from "../../config/app.config";
import ApiService from "../../utils/api-service";

class AuthService extends ApiService {
    constructor(url) {
        super(url);
        this._token = null;

        this._onAuthCallbacks = [];
    }

    _getToken() {
        return this._token;
    }

    getActiveToken() {
        return this._token;
    }

    async login(email, password) {
        const resp = await this.generateResponse(
            await this.request(
                '/login', 
                'POST', 
                new URLSearchParams({ email, password }),
                null,
                false,
                false
            )
        );

        if(!resp.err && resp.data && resp.data.token) {
            // If api context is not null, then check if user has the autorization
            if(config.apiContext && resp.data.context && !resp.data.context.includes(config.apiContext))
                return { success: false, reason: 2 };

            this._token = resp.data.token;
            return {
                success: true
            };
        }

        return {
            success: false,
            reason: 1
        };
    }

    async checkToken(token) {
        const resp = await this.generateResponse(
            await this.request(
                '/check_token', 
                'GET', 
                null, 
                { 'x-access-token': `${token}` }, 
                false,
                false
            )
        );

        if(!resp.err && resp.data && resp.data.success) {
            // If api context is not null, then check if user has the autorization
            if(config.apiContext && resp.data.context && (!resp.data.context.includes(config.apiGPTContext) && !resp.data.context.includes(config.apiMistralContext)))
                return { success: false, reason: 2 };

            this._token = token;
            return { success: true };
        }

        return { success: false, reason: 1 };
    }

    async logout() {
        this._token = null;
    }
};

const authService = new AuthService(config.authApiUrl);
export default authService;