import config from "../../config/app.config";
import ApiService from "../../utils/api-service";
import authService from "./auth.service";

class LLMService extends ApiService {
    _getToken() {
        return authService.getActiveToken();
    }

    async requestLLM(messages, mode = undefined) {
        const body = {
            messages
        };

        if(mode) body.mode = mode;

        const res = await this.generateResponse(
            await this.request('/postOneAnswer/', 'POST', body, { context: config.apiLLMContext })
        );

        if(res.err || !res.data) return null;

        // [Temp] special post process
        return { ...res.data, generated: res.data.llm_answer };
    }
};

const llmService = new LLMService(config.llmApiUrl);
export default llmService;