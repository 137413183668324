import React, { useEffect, useState } from "react";
//import { BrowserRouter, Routes, Route } from "react-router-dom";
import routes from "./config/routes.config";
import config from "./config/app.config";

function App() {
  const [currentRoute, setCurrentRoute] = useState(config.authMode == 'internal' ? '/' : '/conversation');

  const getRouteComponent = () => {
    for(const [path, Component] of Object.entries(routes)) {
      if(path.toLowerCase() == currentRoute.toLowerCase()
        || (`${config.urlPrefix.toLowerCase()}${path.toLowerCase()}` == currentRoute.toLowerCase())) 
        return (<Component navigate={setCurrentRoute} />);
    }

    return (<NotFoundComponent />);
  };

  return (
    <>
      <style>
        {
          `
            :root {
              --main-color: ${config.mainColor};
            }
          `
        }
      </style>
      { getRouteComponent() }
    </>
  );
}

function NotFoundComponent() {
  return (
    <h2>404 - Page not found</h2>
  );
};

export default App;
