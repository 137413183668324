import ConversationModule from "../modules/conversation/conversation";
import HeaderModule from "../modules/header/header";

const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
};

export default function ConversationPage({
    navigate
}) {
    return (
        <div
            style={pageStyle}
        >
            <HeaderModule navigate={navigate} />
            <ConversationModule navigate={navigate} />
        </div>
    );
};