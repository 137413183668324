import IconButtonComponent from "../../components/icon-button/icon-button";
import HomeIcon from "../../components/icons/home-icon/home-icon";
import config from "../../config/app.config";
import authService from "../../services/api/auth.service";
import storageService from "../../services/storage.service";
import styles from "./header.module.css";

export default function HeaderModule({
    navigate
}) {
    const onHomeClick = () => {
        if(config.authMode == 'internal') {
            storageService.remove('token');
            authService.logout();
            navigate('/');
        }
        else
            window.location.href = config.authExternalUrl;
    };

    return (
        <div className={styles.header}>
            <IconButtonComponent onClick={onHomeClick} icon={<HomeIcon />} color="#fefefe" shape="square" />
            <h1>{config.name}</h1>
        </div>
    );
};