import styles from "./error-message.module.css";

function MessageCloseComponent({
    onClose
}) {
    return (
        <div className={styles.closeWrapper}>
            <span onClick={onClose}>x</span>
        </div>
    );
}

export default function ErrorMessageComponent({
    anim,
    onClose,
    children
}) {
    return (
        <div className={`${styles.errorMessage} ${anim ? styles.animate : ''}`}>
            <span className={styles.content}>{ children }</span>
            {
                onClose && (
                    <MessageCloseComponent onClose={onClose} />
                )
            }
        </div>
    );
};