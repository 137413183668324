import { useState } from "react";
import styles from "./auth.module.css";
import authService from "../../services/api/auth.service";
import storageService from "../../services/storage.service";
import ErrorMessageComponent from "../../components/error-message/error-message";
import ButtonComponent from "../../components/button/button";
import TextInputComponent from "../../components/text-input/text-input";
import FixedWrapperComponent from "../../components/fixed-wrapper/fixed-wrapper";
import { useIsAuthEffect } from "../../utils/auth.utils";
import config from "../../config/app.config";

export default function AuthModule({
    navigate
}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [fetching, setFetching] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const onLogin = () => {
        if(fetching) return;

        setFetching(true);
        authService.login(email, password).then(res => {
            if(!res.success) {
                setErrorMessage(
                    res.reason == 1 ? 'Adresse email ou mot de passe invalide.' : "Votre compte n'a pas accès au service LLM."
                );
                setPassword('');
                setFetching(false);
                return;
            }

            storageService.set('token', authService.getActiveToken());
            navigate('/conversation');
        });
    };

    useIsAuthEffect({
        onSuccess: () => navigate('/conversation'),
        onFailed: () => setFetching(false)
    });

    return (
        <div className={styles.authWrapper}>
            <h3>Connexion</h3>
            <div className={styles.authForm}>
                <TextInputComponent
                    type="email"
                    value={email}
                    onChange={v => setEmail(v)}
                    placeholder="Adresse email"
                    disabled={fetching}
                />
                <TextInputComponent
                    type="password"
                    value={password}
                    onChange={v => setPassword(v)}
                    placeholder="Mot de passe"
                    onConfirmPress={onLogin}
                    disabled={fetching}
                />
                <ButtonComponent
                    onClick={onLogin}
                    disabled={fetching}
                    color={config.mainColor}
                >
                    Se connecter
                </ButtonComponent>
            </div>
            { errorMessage && (
                    <FixedWrapperComponent>
                        <ErrorMessageComponent
                            anim
                            onClose={() => setErrorMessage(null)}
                        >
                            { errorMessage }
                        </ErrorMessageComponent>
                    </FixedWrapperComponent>
            ) }
        </div>
    );
};