export default function CircleArrowIcon() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <mask maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                    <rect width="40" height="40" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_1741_25)">
                    <path d="M18.3333 19.6667V25C18.3333 25.4722 18.493 25.8681 18.8125 26.1875C19.1319 26.5069 19.5278 26.6667 20 26.6667C20.4722 26.6667 20.868 26.5069 21.1875 26.1875C21.5069 25.8681 21.6666 25.4722 21.6666 25V19.6667L23.1666 21.1667C23.4722 21.4722 23.8611 21.625 24.3333 21.625C24.8055 21.625 25.1944 21.4722 25.5 21.1667C25.8055 20.8611 25.9583 20.4722 25.9583 20C25.9583 19.5278 25.8055 19.1389 25.5 18.8333L21.1666 14.5C20.8333 14.1667 20.4444 14 20 14C19.5555 14 19.1666 14.1667 18.8333 14.5L14.5 18.8333C14.1944 19.1389 14.0416 19.5278 14.0416 20C14.0416 20.4722 14.1944 20.8611 14.5 21.1667C14.8055 21.4722 15.1944 21.625 15.6666 21.625C16.1389 21.625 16.5278 21.4722 16.8333 21.1667L18.3333 19.6667ZM20 36.6667C17.6944 36.6667 15.5278 36.2292 13.5 35.3542C11.4722 34.4792 9.70831 33.2917 8.20831 31.7917C6.70831 30.2917 5.52081 28.5278 4.64581 26.5C3.77081 24.4722 3.33331 22.3055 3.33331 20C3.33331 17.6944 3.77081 15.5278 4.64581 13.5C5.52081 11.4722 6.70831 9.70833 8.20831 8.20833C9.70831 6.70833 11.4722 5.52083 13.5 4.64583C15.5278 3.77083 17.6944 3.33333 20 3.33333C22.3055 3.33333 24.4722 3.77083 26.5 4.64583C28.5278 5.52083 30.2916 6.70833 31.7916 8.20833C33.2916 9.70833 34.4791 11.4722 35.3541 13.5C36.2291 15.5278 36.6666 17.6944 36.6666 20C36.6666 22.3055 36.2291 24.4722 35.3541 26.5C34.4791 28.5278 33.2916 30.2917 31.7916 31.7917C30.2916 33.2917 28.5278 34.4792 26.5 35.3542C24.4722 36.2292 22.3055 36.6667 20 36.6667Z" fill="#B2B0B7"/>
                </g>
            </g>
        </svg>
    );
};