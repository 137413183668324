export default function copyToClipboard(text) {
    navigator.clipboard.writeText(text);

    /* (DEPRECATED)
    const elem = document.createElement('input');
    elem.style.display = 'none';
    elem.type = 'text';
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    console.log(text);*/
};