import * as React from "react";

import styles from "./no-token.module.css";

export default function NoTokenModule() {
    return (
        <div className={styles.noToken}>
            <h1>Local Error - Token not found or invalid</h1>
        </div>
    );
};