import styles from "./message.module.css";

export default function MessageComponent({
    fromIcon,
    from,
    children
}) {
    return (
        <div className={styles.messageWrapper}>
            <div className={styles.messageHeader}>
                {
                    fromIcon && (
                        <span className={styles.messageAuthorIcon}>{ fromIcon }</span>
                    )
                }
                <span className={styles.messageAuthor}>
                    { from }
                </span>
            </div>
            <div className={styles.messageContent}>
                { children }
            </div>
        </div>
    );
};