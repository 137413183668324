import * as React from "react";

import styles from "./text-input.module.css";

export default function TextInputComponent({
    name,
    value,
    type,
    onChange,
    onConfirmPress,
    placeholder,
    className,
    label,
    disabled,
    blockInteract,
    inputRef,
    paragraphMode
}) {
    return (
        <span className={`${styles.textInput} ${className ? className : ''} ${disabled ? styles.disabled : ''}`}>
            {
                label && (
                    <label>{label}</label>
                )
            }
            {
                paragraphMode !== true && (
                    <input 
                        type={type ? type : 'text'} 
                        ref={inputRef} 
                        name={name} 
                        disabled={disabled || blockInteract} 
                        value={value} 
                        onChange={e => onChange && onChange(e.target.value)} 
                        placeholder={placeholder} 
                        onKeyUp={e => e.code == 'Enter' && !disabled && onConfirmPress && onConfirmPress()}
                    />
                )
            }
            {
                paragraphMode === true && (
                    <textarea rows={4} name={name} ref={inputRef} disabled={disabled || blockInteract} value={value} onChange={e => onChange && onChange(e.target.value)} placeholder={placeholder}>
                    </textarea>
                )
            }
        </span>
    );
};