export default function UserIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
            <rect width="47" height="47" rx="23.5" fill="#F43A93"/>
            <mask maskUnits="userSpaceOnUse" x="13" y="14" width="20" height="20">
                <rect x="13" y="14" width="20" height="20" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1739_3259)">
                <path d="M18 29.8333V30.25C18 30.5972 17.8785 30.8923 17.6354 31.1354C17.3924 31.3784 17.0972 31.5 16.75 31.5C16.4028 31.5 16.1076 31.3784 15.8646 31.1354C15.6215 30.8923 15.5 30.5972 15.5 30.25V24.2916C15.5 24.1944 15.5069 24.0972 15.5208 24C15.5347 23.9027 15.5556 23.8125 15.5833 23.7291L17.1458 19.2916C17.2569 18.9583 17.4583 18.6875 17.75 18.4791C18.0417 18.2708 18.3681 18.1666 18.7292 18.1666H27.2708C27.6319 18.1666 27.9583 18.2708 28.25 18.4791C28.5417 18.6875 28.7431 18.9583 28.8542 19.2916L30.4167 23.7291C30.4444 23.8125 30.4653 23.9027 30.4792 24C30.4931 24.0972 30.5 24.1944 30.5 24.2916V30.25C30.5 30.5972 30.3785 30.8923 30.1354 31.1354C29.8924 31.3784 29.5972 31.5 29.25 31.5C28.9028 31.5 28.6076 31.3784 28.3646 31.1354C28.1215 30.8923 28 30.5972 28 30.25V29.8333H18ZM17.8333 22.3333H28.1667L27.2917 19.8333H18.7083L17.8333 22.3333ZM19.25 27.3333C19.5972 27.3333 19.8924 27.2118 20.1354 26.9687C20.3785 26.7257 20.5 26.4305 20.5 26.0833C20.5 25.7361 20.3785 25.4409 20.1354 25.1979C19.8924 24.9548 19.5972 24.8333 19.25 24.8333C18.9028 24.8333 18.6076 24.9548 18.3646 25.1979C18.1215 25.4409 18 25.7361 18 26.0833C18 26.4305 18.1215 26.7257 18.3646 26.9687C18.6076 27.2118 18.9028 27.3333 19.25 27.3333ZM26.75 27.3333C27.0972 27.3333 27.3924 27.2118 27.6354 26.9687C27.8785 26.7257 28 26.4305 28 26.0833C28 25.7361 27.8785 25.4409 27.6354 25.1979C27.3924 24.9548 27.0972 24.8333 26.75 24.8333C26.4028 24.8333 26.1076 24.9548 25.8646 25.1979C25.6215 25.4409 25.5 25.7361 25.5 26.0833C25.5 26.4305 25.6215 26.7257 25.8646 26.9687C26.1076 27.2118 26.4028 27.3333 26.75 27.3333ZM17.1667 28.1666H28.8333V24H17.1667V28.1666Z" fill="white"/>
            </g>
        </svg>
    );
};