import AuthModule from "../modules/auth/auth";

export default function LoginPage({
    navigate
}) {
    return (
        <>
            <AuthModule navigate={navigate} />
        </>
    );
};