import { useEffect, useRef, useState } from "react";
import styles from "./messages-wrapper.module.css";
import UserMessageComponent from "../user-message/user-message";
import LlmMessageComponent from "../llm-message/llm-message";

export default function MessageWrapperComponent({
    messages,
    onScroll
}) {
    const [scrollHandlerState] = useState({
        handler: null
    });

    const wrapperRef = useRef();

    useEffect(() => {
        if(!wrapperRef.current) return;

        wrapperRef.current.addEventListener('scroll', () => {
            if(!scrollHandlerState || !scrollHandlerState.handler) return;
            scrollHandlerState.handler();
        });
    }, [wrapperRef]);

    useEffect(() => {
        scrollHandlerState.handler = onScroll;
    }, [onScroll]);

    return (
        <div 
            className={`${styles.messagesWrapper} ${!messages.length ? styles.hidden : ''}`} 
            ref={wrapperRef}
        >
            {
                messages.filter(message => !message.hidden).map((message, index) => (
                    message.role == 'user'
                        ?
                    (
                        <UserMessageComponent
                            key={`conversation-message-${index}`}
                            message={message}
                        />
                    )
                        :
                    (
                        <LlmMessageComponent 
                            key={`conversation-message-${index}`}
                            message={message}
                            messageIndex={index}
                        />
                    )
                ))
            }
        </div>
    );
};