import { useEffect } from "react";
import authService from "../services/api/auth.service";
import storageService from "../services/storage.service";

export function useIsAuthEffect({
    onSuccess,
    onFailed
}) {
    useEffect(() => {
        if(authService.getActiveToken()) {
            if(onSuccess) onSuccess();
            return;
        }

        const storageToken = storageService.get('token');
        if(!storageToken) {
            if(onFailed) onFailed();
            return;
        }

        authService.checkToken(storageToken).then(res => {
            if(!res.success) {
                storageService.remove('token');
                if(onFailed) onFailed();
                return;
            }

            if(onSuccess) onSuccess();
        });
    }, []);
};