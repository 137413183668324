const config = {
    appMode: process.env.REACT_APP_MODE ?? 'dev',
    authMode: process.env.REACT_APP_AUTH_MODE ?? 'internal',
    authExternalUrl: process.env.REACT_APP_AUTH_EXTERNAL_URL ?? '',
    authApiUrl: process.env.REACT_APP_API_AUTH_URL ?? '',
    llmApiUrl: process.env.REACT_APP_API_LLM_URL ?? '',
    mainColor: process.env.REACT_APP_MAIN_COLOR ?? 'dodgerblue',
    name: process.env.REACT_APP_NAME ?? '',
    apiLLMContext: process.env.REACT_APP_API_LLM_CONTEXT ?? '',
    urlPrefix: process.env.REACT_APP_URL_PREFIX ?? ''
};
export default config;